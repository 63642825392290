import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import moment from "moment";
import reportService from "./reports-service";
import exportLimit from "../../common/export-limit.vue";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      sDate: "",
      eDate: "",
      showStartDate: true,
      showEndDate: true,
      startDate: false,
      endDate: false,
      selectedProject: "",
      projectList: [],
      disableAll: true,
      disableSpecific: true,
      selectedBusiness: "",
      businessData: [],
      selectedWarehouse: "",
      warehouseData: [],
      selectedClass: "",
      classData: [],
      selectedOEM: "",
      OEMData: [],
      selectedPOType: "",
      poTypeData: [],
      dateSelected: false,
      runDisabled: true,
      specificDialog: false,
      partClassSearchValid: false,
      page: 1,
      totalPages: 0,
      showPartNum: false,
      hideSpecific: false,
      hideAll: false,
      partSearchKey: "",
      showPartNumDetails: false,
      partResultList: [],
      showPartDetails: false,
      showBusiness: false,
      showWarehouse: false,
      showClass: false,
      showOEM: false,
      showPOType: false,
      receiptData: [],
      excelName: "",
      totalRecords: 0,
      exportDisable: true,
      export50kData: false,
      exportDialog: false,
      showData: false,
      debouncedGetPartList: [],
      headerPOReceipt: [
        { text: "Business", value: "Business", class: "primary customwhite--text", width: "18%" },
        { text: "PO#", value: "PO#", class: "primary customwhite--text", width: "10%" },
        { text: "Line", value: "Line", class: "primary customwhite--text", width: "2%" },
        { text: "RMA", value: "RMA", class: "primary customwhite--text", width: "5%" },
        { text: "Item", value: "Item", class: "primary customwhite--text", width: "7%" },
        { text: "OEM Part", value: "OEM Part", class: "primary customwhite--text", width: "6%" },
        { text: "Description", value: "Description", class: "primary customwhite--text", width: "17%" },
        { text: "Quantity", value: "Qty", class: "primary customwhite--text", width: "4%" },
        { text: "Warr", value: "Warr", class: "primary customwhite--text", width: "4%" },
        { text: "Serial No", value: "Serial#", class: "primary customwhite--text", width: "5%" },
        { text: "BCN", value: "BCN", class: "primary customwhite--text", width: "5%" },
        { text: "Date Received", value: "Date_Rcvd", class: "primary customwhite--text", width: "17%" },
      ],
      json_fields: {
        Business: "Business",
        "PO#": "PO#",
        Line: "Line",
        "Vendor ID": "Vendor_ID",
        "Vendor Name": "Vendor_Name",
        "Address 1": "Addr1",
        "Address 2": "Addr2",
        City: "City",
        State: "State",
        "Zip Code": "Zip Code",
        Country: "Country",
        ATTN: "ATTN",
        "Order Number": "Order_No",
        "Vendor SO": "Vend_SO",
        RMA: "RMA",
        Item: "Item",
        "OEM Part": "OEM Part",
        Description: "Description",
        Model: "Model",
        Class: "Class",
        OEM: "OEM",
        "Unit Cost": "Unit_Cost",
        Quantity: "Qty",
        Warranty: "Warr",
        "Serial No": "Serial#",
        BCN: "BCN",
        ESN: "ESN",
        LP: "LP",
        "Date Received": "Date_Rcvd",
        "Order Type": "Order_Type",
        "Customer PO Type": "Cust_PO_Type",
        "Reason Code": "Reason_Code",
        Condition: "Condition",
        DNC: "DNC",
        CRA: "CRA",
        Carrier: "Carrier",
        "Waybill (Inbound)": "Waybill (inbound)",
        "Packslip (Inbound)": "Packslip (inbound)",
        "Vendor Reference Number": "Vendor_Ref_No",
        Location: "Location",
        Warehouse: "Warehouse",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.debouncedGetPartList = Utility.debounce(this.getPartList, 500);
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(this.sDate).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.eDate ? moment(this.eDate).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
    },
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.showStartDate = false;
      this.showEndDate = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
        this.showEndDate = true;
        this.eDate = null;
      });
      this.selectedProject = "";
      this.projectList = [];
      this.disableAll = true;
      this.disableSpecific = true;
      this.selectedBusiness = "";
      this.businessData = [];
      this.selectedWarehouse = "";
      this.warehouseData = [];
      this.selectedClass = "";
      this.classData = [];
      this.selectedOEM = "";
      this.OEMData = [];
      this.selectedPOType = "";
      this.poTypeData = [];
      this.dateSelected = false;
      this.runDisabled = true;
      this.specificDialog = false;
      this.partClassSearchValid = false;
      this.page = 1;
      this.totalPages = 0;
      this.showPartNum = false;
      this.hideSpecific = false;
      this.hideAll = false;
      this.partSearchKey = "";
      this.showPartNumDetails = false;
      this.partResultList = [];
      this.showPartDetails = false;
      this.showBusiness = false;
      this.showWarehouse = false;
      this.showClass = false;
      this.showOEM = false;
      this.showPOType = false;
      this.receiptData = [];
      this.excelName = "";
      this.totalRecords = 0;
      this.exportDisable = true;
      this.export50kData = false;
      this.exportDialog = false;
      this.showData = false;
    },
    // set end Date
    setEndDate() {
      let minEndDate = new Date(this.sDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0).toISOString().substr(0, 10);
    },
    //On click continue
    async onContinue() {
      !this.sDate ? (this.sDate = new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10)) : "";
      let obj = {
        pass: 0,
        user_id: parseInt(this.userId),
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        proj_id: 0,
        part_num: "",
        bu_id: 0,
        class_id: 0,
        ware_id: 0,
        oem_id: 0,
        potype_id: 0,
        showcost: true,
        PageNumber: 0,
        export: 0,
        guid: 0,
      };
      let showMessage = false;
      let projData = await reportService.purchaseOrderReceipts("post", obj, showMessage);
      if (projData.message !== "NA") {
        this.projectList = projData;
        this.dateSelected = true;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Data Available change the Start Date",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.dateSelected = false;
      }
    },
    //Project Selected
    async onChangeProject() {
      let obj = {
        pass: 1,
        user_id: parseInt(this.userId),
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        proj_id: parseInt(this.selectedProject),
        part_num: "",
        bu_id: 0,
        class_id: 0,
        ware_id: 0,
        oem_id: 0,
        potype_id: 0,
        showcost: true,
        PageNumber: 0,
        export: 0,
        guid: 0,
      };
      let showMessage = false;
      let busData = await reportService.purchaseOrderReceipts("post", obj, showMessage);
      if (busData.message !== "NA") {
        this.businessData = busData;
        this.disableAll = false;
        this.disableSpecific = false;
        this.runDisabled = true;
        this.showPartNum = true;
        this.hideSpecific = false;
        this.hideAll = false;
        this.partSearchKey = "";
        this.showPartNumDetails = false;
        this.partResultList = [];
        this.showPartDetails = false;
        this.showBusiness = false;
        this.showWarehouse = false;
        this.showClass = false;
        this.showOEM = false;
        this.showPOType = false;
        this.receiptData = [];
        this.totalRecords = 0;
        this.totalPages = 0;
        this.exportDisable = true;
        this.showData = false;
        this.clearPartShip();
      }
    },
    //All Part Clicked
    allPartNumber() {
      this.showBusiness = true;
      this.showPartType = false;
      this.showParentClass = false;
      this.showPartClass = false;
      this.showWarehouse = false;
      this.showInventoryLoc = false;
      this.runDisabled = true;
      this.hideSpecific = true;
      this.showData = false;
      this.clearPartShip();
    },
    async searchPartNum() {
      this.partSearchKey = this.partSearchKey?.toUpperCase();
      if (this.partSearchKey?.length > 2) {
        this.debouncedGetPartList();
      }
    },
    async getPartList() {
      this.showPartNumDetails = true;
      let SearchObj = {
        UserId: this.userId,
        SearchStr: this.partSearchKey,
        proj_key: this.selectedProject,
      };
      let partResultData = await reportService.showPartNumDetailsData("post", SearchObj, false);
      this.partResultList = partResultData?.Resultset;
    },
    //Part Ship Clear button
    clearPartShip() {
      this.showPartDetails = false;
      this.partSearchKey = "";
      this.partResultList = [];
      this.partNum = "";
      this.partDesc = "";
    },
    //Part Description once clicked
    showPartDesc(item) {
      this.showPartDetails = true;
      this.partNum = item.PartNo;
      this.partDesc = item.Description;
      this.partSearchKey = item.PartNo;
      this.showPartNumDetails = false;
      this.partDetailsShow = false;
      this.specificDialog = false;
      this.showBusiness = false;
      this.showPartType = false;
      this.showParentClass = false;
      this.showPartClass = false;
      this.showWarehouse = false;
      this.showInventoryLoc = false;
      this.runDisabled = false;
      this.hideAll = true;
      this.showData = false;
    },
    //Business selected
    async businessSelected() {
      let obj = {
        pass: 2,
        user_id: parseInt(this.userId),
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        proj_id: parseInt(this.selectedProject),
        part_num: "",
        bu_id: parseInt(this.selectedBusiness),
        class_id: 0,
        ware_id: 0,
        oem_id: 0,
        potype_id: 0,
        showcost: true,
        PageNumber: 0,
        export: 0,
        guid: 0,
      };
      let showMessage = false;
      let otherData = await reportService.purchaseOrderReceipts("post", obj, showMessage);
      if (otherData.message !== "NA") {
        this.warehouseData = otherData;
        this.selectedWarehouse = "";
        this.selectedClass = "";
        this.selectedOEM = "";
        this.selectedPOType = "";
        this.runDisabled = true;
        this.partSearchKey = "";
        this.showPartNumDetails = false;
        this.partResultList = [];
        this.showPartDetails = false;
        this.showWarehouse = true;
        this.showClass = false;
        this.showOEM = false;
        this.showPOType = false;
        this.showData = false;
      }
    },
    //Warehouse selected
    warehouseSelected() {
      this.selectedClass = "";
      this.selectedOEM = "";
      this.selectedPOType = "";
      this.showClass = true;
      this.showOEM = false;
      this.showPOType = false;
      this.runDisabled = true;
      this.showData = false;
      if (this.selectedWarehouse !== 0) {
        this.classData = this.warehouseData.filter((obj) => obj.ware_key == this.selectedWarehouse);
      } else {
        this.classData = this.warehouseData;
      }
    },
    //Class selected
    classSelected() {
      this.selectedOEM = "";
      this.selectedPOType = "";
      this.runDisabled = true;
      this.showOEM = true;
      this.showPOType = false;
      this.showData = false;
      if (this.selectedClass !== 0) {
        this.OEMData = this.classData.filter((obj) => obj.class_key == this.selectedClass);
      } else {
        this.OEMData = this.classData;
      }
    },
    //OEM Selected
    OEMSelected() {
      this.selectedPOType = "";
      this.runDisabled = true;
      this.showPOType = true;
      this.showData = false;
      if (this.selectedOEM !== 0) {
        this.poTypeData = this.OEMData.filter((obj) => obj.oem_key == this.selectedOEM);
      } else {
        this.poTypeData = this.OEMData;
      }
    },
    //Run Report
    async runReport() {
      let obj = {
        pass: 3,
        user_id: parseInt(this.userId),
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        proj_id: parseInt(this.selectedProject),
        part_num: this.partNum,
        bu_id: parseInt(this.selectedBusiness == null || this.selectedBusiness == "" ? 0 : this.selectedBusiness),
        class_id: parseInt(this.selectedClass == null || this.selectedClass == "" ? 0 : this.selectedClass),
        ware_id: parseInt(this.selectedWarehouse == null || this.selectedWarehouse == "" ? 0 : this.selectedWarehouse),
        oem_id: parseInt(this.selectedOEM == null || this.selectedOEM == "" ? 0 : this.selectedOEM),
        potype_id: parseInt(this.selectedPOType == null || this.selectedPOType == "" ? 0 : this.selectedPOType),
        showcost: true,
        PageNumber: 1,
        export: 0,
        guid: 0,
      };
      let showMessage = false;
      let reportData = await reportService.purchaseOrderReceipts("post", obj, showMessage);
      if (reportData.message !== "NA") {
        if (reportData.Resultset.length > 0) {
          const newArr = reportData.Resultset.map((obj) => {
            return {
              ...obj,
              Date_Rcvd: Utility.convertESTToLocal(obj.Date_Rcvd),
            };
          });
          this.receiptData = newArr;
          this.totalRecords = reportData.TotalRecords;
          this.exportDisable = false;
          this.totalPages = reportData.TotalPages;
          this.showData = true;
        } else {
          this.receiptData = [];
          this.totalRecords = 0;
          this.totalPages = 0;
          this.exportDisable = true;
          this.showData = false;
          let Alert = {
            type: "error",
            isShow: true,
            message: "No records based on the selected criteria",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
    //Run Report
    async onClickSearch(page) {
      let obj = {
        pass: 3,
        user_id: parseInt(this.userId),
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        proj_id: parseInt(this.selectedProject),
        part_num: this.partNum,
        bu_id: parseInt(this.selectedBusiness == null || this.selectedBusiness == "" ? 0 : this.selectedBusiness),
        class_id: parseInt(this.selectedClass == null || this.selectedClass == "" ? 0 : this.selectedClass),
        ware_id: parseInt(this.selectedWarehouse == null || this.selectedWarehouse == "" ? 0 : this.selectedWarehouse),
        oem_id: parseInt(this.selectedOEM == null || this.selectedOEM == "" ? 0 : this.selectedOEM),
        potype_id: parseInt(this.selectedPOType == null || this.selectedPOType == "" ? 0 : this.selectedPOType),
        showcost: true,
        PageNumber: page,
        export: 0,
        guid: 0,
      };
      let showMessage = false;
      let reportData = await reportService.purchaseOrderReceipts("post", obj, showMessage);
      if (reportData.message !== "NA") {
        if (reportData.Resultset.length > 0) {
          const newArr = reportData.Resultset.map((obj) => {
            return {
              ...obj,
              Date_Rcvd: Utility.convertESTToLocal(obj.Date_Rcvd),
            };
          });
          this.receiptData = newArr;
          this.totalRecords = reportData.TotalRecords;
          this.exportDisable = false;
          this.totalPages = reportData.TotalPages;
        } else {
          this.receiptData = [];
          this.totalRecords = 0;
          this.totalPages = 0;
          this.exportDisable = true;
          let Alert = {
            type: "error",
            isShow: true,
            message: "No records based on the selected criteria",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
    //Export Data using component
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    //To export the Excel file
    async exportExcel() {
      if (this.totalRecords <= 15000 || this.export50kData) {
        let obj = {
          pass: 3,
          user_id: parseInt(this.userId),
          lcsdate: Utility.convertLocalToUTC(this.sDate),
          lcedate: Utility.convertLocalToUTC(this.eDate),
          proj_id: parseInt(this.selectedProject),
          part_num: this.partNum,
          bu_id: parseInt(this.selectedBusiness == null || this.selectedBusiness == "" ? 0 : this.selectedBusiness),
          class_id: parseInt(this.selectedClass == null || this.selectedClass == "" ? 0 : this.selectedClass),
          ware_id: parseInt(this.selectedWarehouse == null || this.selectedWarehouse == "" ? 0 : this.selectedWarehouse),
          oem_id: parseInt(this.selectedOEM == null || this.selectedOEM == "" ? 0 : this.selectedOEM),
          potype_id: parseInt(this.selectedPOType == null || this.selectedPOType == "" ? 0 : this.selectedPOType),
          showcost: true,
          PageNumber: 1,
          export: 1,
          guid: 0,
        };
        this.excelName =
          "Purchase_Order_Receipt_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
        const response = await this.axios.post("/rt/po_receipt_report", obj);
        let responseData = JSON.parse(response.data.body.message);
        const newArr = responseData.map((obj) => {
          return {
            ...obj,
            Date_Rcvd: Utility.convertESTToLocal(obj.Date_Rcvd),
          };
        });
        this.export50kData = false;
        return newArr;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //End the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
